<template>
  <div class="relative">
    <div class="flex flex-col items-center">
      <div
        v-click-outside="hideDragging"
        :class="[wrapperClass, { 'border-gray-600': dragging }, { 'border-gray-400': !dragging }]"
        @dragenter.prevent.stop="dragging = true"
        @dragover.prevent.stop="dragging = true"
        @dragleave.prevent.stop="dragging = false"
        @drop.prevent.stop="onChange"
        class="flex border-dashed border-2 rounded-md w-full"
      >
        <div class="flex flex-col justify-center items-center w-full my-12 mx-auto">
          <UploadImageIcon class="w-14 h-14"></UploadImageIcon>

          <div class="flex items-center mt-3">
            <label
              :class="{
                'text-defaultInteractive bg-defaultInteractive-transparent hover:bg-defaultInteractive':
                  isColorDefault(),
                'text-primary bg-primary-transparent hover:bg-primary': !isColorDefault(),
              }"
              class="animation-focus-default cursor-pointer text-sm mr-1 bg-opacity-20 hover:text-white font-semibold py-1 px-2 rounded-md"
            >
              Upload a file
              <input
                id="fileInput"
                ref="fileInput"
                class="w-[100%] h-[100%] top-0 left-0 opacity-0 absolute"
                type="file"
                :accept="acceptedExtensions"
                @change="onChange"
                :multiple="isMultiple"
              />
            </label>
            <span class="hidden sm:flex text-sm">or drag and drop</span>
          </div>
          <div class="flex justify-center text-sm sm:text-small text-gray-600 mt-1">
            PNG, JPG, GIF up to 10MB
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FileUtil } from '@/utils/file.util';
import UploadImageIcon from '@/assets/icon_upload_image.svg';
import { DEFAULT_COLOR } from '@/components/constants/groupColors';

const IMAGE_FILE_EXTENSIONS = ['jpg', 'png', 'jpeg', 'gif'];
export default {
  name: 'FileField',
  components: {
    UploadImageIcon,
  },
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    fileExtensions: { type: Array, default: () => IMAGE_FILE_EXTENSIONS },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dragging: false,
      defaultColor: DEFAULT_COLOR,
    };
  },
  computed: {
    ...mapGetters(['group']),
    acceptedExtensions() {
      return this.fileExtensions.map((item) => `.${item.toUpperCase()}`).join(', ');
    },
  },

  methods: {
    isColorDefault() {
      return this.group.primaryColor === this.defaultColor;
    },

    hideDragging() {
      this.dragging = false;
    },

    async onChange(event) {
      try {
        if (this.isMultiple) {
          const files = FileUtil.getFilesFromEvent(event);
          this.$emit('input', Array.from(files));
        } else {
          const file = FileUtil.getFileFromEvent(event);
          const url = await FileUtil.getDataUrl(file);
          const blob = await FileUtil.getBlob(file);

          this.$emit('input', {
            url,
            name: file.name,
            blob,
          });
        }
        event.target.value = '';
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
