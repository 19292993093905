<template>
  <form
    v-if="updatedMedia.length"
    class="relative overflow-y-auto overflow-x-hidden md:overflow-visible"
  >
    <div class="relative h-[160px] md:h-[250px] px-[26px] mb-2 rounded-md bg-gray-100">
      <button
        type="button"
        v-if="updatedMedia.length > 1"
        @click="showPrev"
        class="slick-btn w-[44px] h-[44px] pt-1 pr-1 rounded-full bg-white absolute -translate-y-1/2 top-1/2 shadow-drop z-10 left-0 md:left-[-22px] disabled:pointer-events-none disabled:opacity-60"
        :disabled="currentIndex === 0"
      >
        <Chevron class="rotate-90 scale-50" />
      </button>
      <VueSlickCarousel
        focusOnSelect
        fade
        :infinite="false"
        :arrows="false"
        adaptiveHeight
        class="h-[250px]"
        ref="main"
        @afterChange="changeItem"
      >
        <img
          class="!object-contain h-[160px] md:h-[250px]"
          alt="preview-photo"
          v-for="(mediaItem, index) in updatedMedia"
          :key="index"
          :src="mediaItem.file"
        />
      </VueSlickCarousel>
      <button
        type="button"
        v-if="updatedMedia.length > 1"
        @click="showNext"
        class="slick-btn w-[44px] h-[44px] pl-1 rounded-full bg-white absolute -translate-y-1/2 top-1/2 shadow-drop z-10 right-0 md:right-[-22px] disabled:pointer-events-none disabled:opacity-60"
        :disabled="currentIndex === updatedMedia.length - 1"
      >
        <Chevron class="-rotate-90 scale-50" />
      </button>
    </div>
    <div class="flex flex-col md:flex-row gap-2">
      <div class="relative flex-1">
        <label :for="name" class="flex text-sm text-gray-800 mb-2">Name</label>
        <input
          :id="name"
          type="text"
          v-model="name"
          @blur="$v.name.$touch()"
          @input="(event) => (updatedMedia[currentIndex].name = event.target.value)"
          placeholder="Enter name"
          class="input h-[56px] py-4 px-5 w-full text-gray-900 bg-white rounded shadow border border-gray-300 text-base placeholder-gray-500 focus:outline-none transition-colors duration-200 ease"
        />
        <span v-if="$v.name.$dirty && !$v.name.required" class="error-message">
          This field is required.
        </span>
        <span v-else-if="$v.name.$dirty && !$v.name.maxLength" class="error-message">
          Max length is 50 characters.
        </span>
        <span
          v-if="errorMessage"
          class="absolute -bottom-6 left-0 text-small text-red-600 font-light"
        >
          {{ errorMessage }}
        </span>
      </div>

      <div class="relative flex-1 mt-2 md:mt-0">
        <MapsAutocompleteInput
          id="city"
          :search-types="'(cities)'"
          label="Location"
          placeholder="Current city"
          :value="location"
          name="location"
          @updateFullAddress="handleUpdateLocation"
          is-optional
          scheme="secondary"
          is-active-clear-btn
        />
      </div>
    </div>
    <div class="relative mt-4">
      <label for="description" class="flex items-center text-sm text-gray-800 mb-2">
        Description <span class="text-gray-600 text-xs ml-2">(max 500 characters)</span>
      </label>
      <textarea
        v-model="description"
        autocomplete="disabled"
        spellcheck="false"
        id="description"
        class="py-[9px] text-base input w-full text-gray-900 bg-white rounded shadow-drop placeholder:text-gray-500 placeholder-gray-600 focus:outline-none px-4 min-h-130px lg:min-h-32 m-1 sm:m-0 border transition-colors duration-200 ease resize-none"
        placeholder="Enter Description"
        @blur="$v.description.$touch()"
        @input="(event) => (updatedMedia[currentIndex].description = event.target.value)"
      />
      <span v-if="$v.description.$dirty && !$v.description.maxLength" class="error-message">
        Max length is 500 characters.
      </span>
    </div>

    <template v-if="updatedMedia.length > 1">
      <p class="text-gray-900 text-sm pt-4 pb-2">All Files</p>

      <VueSlickCarousel
        v-bind="thumbnailSettings"
        :currentSlide="currentIndex"
        ref="thumbnails"
        class="thumbnails"
      >
        <div
          v-for="(mediaItem, index) in updatedMedia"
          :key="index"
          class="relative pb-3.5"
          @click="$refs.main.goTo(index)"
        >
          <span
            v-if="!mediaItem.isValid"
            class="absolute top-[6px] left-[6px] flex justify-center items-center px-1 py-[3px] rounded-sm w-[20px] h-[18px] bg-white"
          >
            <AlertInfoIcon class="alert-icon" />
          </span>
          <img
            class="w-[100px] h-[82px] rounded !object-cover border-transparent hover:border-gray-900 border-[1.5px] border-solid cursor-pointer"
            :class="{
              'border-red-700': !mediaItem.isValid,
              current: currentIndex === index,
            }"
            alt="preview-photo"
            :src="mediaItem.file"
          />
          <span
            v-if="errorList.includes(String(mediaItem.id))"
            class="absolute bottom-0 left-0 text-[10px] text-red-600 font-light;"
          >
            Name already exists
          </span>
        </div>
      </VueSlickCarousel>
    </template>

    <div class="controls w-full flex flex-col-reverse md:flex-row justify-between gap-2 pt-4">
      <Button
        :primary-full-size="false"
        compact
        class="cancel-btn w-full md:w-[220px] h-10 mr-0 md:mr-2"
        type="text"
        @onClick="$emit('cancel')"
      >
        <span class="text-sm text-gray-600">Cancel</span>
      </Button>
      <Button
        class="save-btn w-full md:w-[220px] h-10 ml-0 md:ml-2 mb-2 md:mb-0"
        type="primary"
        :primary-full-size="false"
        compact
        @onClick="submit"
      >
        <span class="text-sm text-white">Save Media</span>
      </Button>
    </div>

    <div
      v-if="isLoading"
      class="w-full h-full absolute top-0 left-0 bg-white opacity-70 flex justify-center items-center"
    >
      <Preloader class="w-32 h-32" />
    </div>
  </form>
</template>

<script>
import Button from '@/components/Button.vue';
import { maxLength, required } from 'vuelidate/lib/validators';
import MapsAutocompleteInput from '@/components/MapsAutocompleteInput.vue';
import { updateBulkMediaService, updateMediaService } from '@/services/media.service';
import { mapGetters } from 'vuex';
import { objectsHaveChanged } from '@/helpers/object.helper';
import Chevron from '@/assets/chevron.svg';
import AlertInfoIcon from '@/assets/icon_alert_info.svg';
import Preloader from '@/assets/icon_circle_animation.svg';

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  name: 'EditMediaForm',
  components: {
    Button,
    Chevron,
    Preloader,
    AlertInfoIcon,
    VueSlickCarousel,
    MapsAutocompleteInput,
  },
  props: {
    media: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      currentIndex: 0,
      name: '',
      location: '',
      description: '',
      errorMessage: '',
      updatedMedia: [],
      errorList: [],
      thumbnailSettings: {
        focusOnSelect: true,
        arrows: false,
        infinite: false,
        asNavFor: this.$refs.main,
        slidesToShow: 8,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['group']),
  },

  validations: {
    name: {
      required,
      maxLength: maxLength(100),
    },
    description: {
      maxLength: maxLength(500),
    },
  },

  watch: {
    currentIndex() {
      this.setFields();
    },
    errorList: {
      handler(newValue) {
        this.updatedMedia = this.updatedMedia.map((m) => {
          if (newValue.includes(String(m.id))) {
            return { ...m, isValid: false };
          }
          return m;
        });
      },
      deep: true,
    },
  },

  mounted() {
    const media = Array.isArray(this.media) ? this.media : Array(this.media);
    this.updatedMedia = media.map((m) => {
      return { ...m, isValid: true };
    });

    this.setFields();
  },

  methods: {
    handleUpdateLocation(address) {
      this.location = address;
      this.updatedMedia[this.currentIndex].location = address;
    },

    changeItem(index) {
      this.$v.$touch();
      this.updatedMedia[this.currentIndex].isValid = !this.$v.$invalid;
      this.currentIndex = index;
      this.$refs.thumbnails.goTo(index);
    },

    showNext() {
      this.$refs.main.next();
    },

    showPrev() {
      this.$refs.main.prev();
    },

    setFields() {
      this.name = this.updatedMedia[this.currentIndex].name;
      this.location = this.updatedMedia[this.currentIndex].location;
      this.description = this.updatedMedia[this.currentIndex].description;
    },

    checkAllMedia() {
      let isValid = true;
      this.updatedMedia = this.updatedMedia.map((m) => {
        if (!m.name || m.name.length > 100 || m.description > 500) {
          isValid = false;
          return { ...m, isValid: false };
        }
        return m;
      });

      return isValid;
    },

    async submit() {
      if (this.checkAllMedia()) {
        this.isLoading = true;
        try {
          if (this.updatedMedia.length > 1) {
            await updateBulkMediaService(this.updatedMedia, this.group.id);
          } else {
            const isMediaUpdated = objectsHaveChanged(this.media, this.updatedMedia[0]);
            if (isMediaUpdated) await updateMediaService(this.updatedMedia[0], this.group.id);
          }

          this.$emit('submit');
        } catch (error) {
          if (this.updatedMedia.length > 1) {
            this.errorList = Object.keys(error);
          } else {
            this.errorMessage = error;
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.error-message {
  @apply absolute -bottom-5 left-0 text-small text-red-600 font-light;
}

.slick-btn {
  &:hover path {
    @apply fill-orange;
  }
}

.thumbnails {
  img.current {
    @apply border-orange-400;
  }
}

.alert-icon {
  circle,
  path {
    @apply stroke-red;
  }
}
</style>
