<template>
  <div
    v-if="imageUrl"
    class="relative rounded border border-gray-200 px-3 py-2 bg-white border-solid"
  >
    <div class="flex gap-2 justify-between">
      <div class="flex gap-2 self-stretch items-center">
        <img
          :src="imageUrl"
          class="rounded"
          :alt="file.name"
          :class="{
            'w-[44px] h-[40px]': scheme === SCHEMES.primary,
            'w-[28px] h-[28px]': scheme === SCHEMES.secondary,
          }"
        />
        <p class="flex flex-col">
          <span class="text-gray-900 text-sm">{{ file.name }}</span>
          <span class="text-gray-600 text-xs">Size - {{ file.size | formatToMegabytes }}</span>
        </p>
      </div>
      <button class="action-btn" type="button" v-tooltip="'Delete'" @click.stop="deleteImage">
        <Delete />
      </button>
    </div>

    <span v-if="isFileTooLarge" class="text-small text-red-600 font-light">
      File size is too large
    </span>
  </div>
</template>
<script>
import Delete from '@/assets/delete.svg';
import { FileUtil } from '@/utils/file.util';

const MAX_FILE_SIZE = 10485760;
const SCHEMES = {
  primary: 'primary',
  secondary: 'secondary',
};

export default {
  name: 'ImageItem',
  components: {
    Delete,
  },
  props: {
    scheme: {
      type: String,
      default: SCHEMES.primary,
    },
    file: {
      type: [File, null],
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      SCHEMES,
      imageUrl: '',
    };
  },

  computed: {
    isFileTooLarge() {
      return this.file.size > MAX_FILE_SIZE;
    },
  },

  async created() {
    this.imageUrl = await FileUtil.getDataUrl(this.file);
  },

  methods: {
    deleteImage() {
      this.$emit('delete', this.index);
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  @apply flex items-center justify-center hover:bg-gray-200 w-[24px] md:w-[40px] h-[24px] md:h-[40px] p-0.5 md:p-2 rounded-sm;
  &:hover {
    path,
    circle,
    ellipse {
      @apply stroke-gray-800;
    }
  }
}
</style>
