<template>
  <div class="w-full px-6 my-8 md:px-0 md:w-2/3 md:mx-auto lg:w-3/5 max-w-screen-md">
    <transition name="fade-left" mode="out-in">
      <template v-if="currentStep === 1 && createGroupAndUser">
        <SignupFormStepOne
          :user="newUser"
          :invalidFields="invalidFields"
          :validate-recaptcha="validateRecaptcha"
        />
      </template>
      <template v-else-if="currentStep === 2">
        <GroupDetailsEdit
          :group="newGroup"
          :invalidFields="invalidFields"
          :handlePhotoUpload="uploadGroupPhoto"
        />
      </template>
      <template v-else-if="currentStep === 3">
        <EditProfileForm
          ref="editProfileForm"
          :currentUser="user"
          :profile="user"
          :onSave="saveProfileChanges"
          :editError="editError"
        />
      </template>
    </transition>

    <template v-if="globalErrors.length">
      <ErrorBox v-for="(error, index) in globalErrors" :message="error" :key="`error${index}`" />
    </template>

    <template v-if="recaptchaErrorMsg">
      <ErrorBox :message="recaptchaErrorMsg" />
    </template>

    <div class="sm:p-3">
      <div class="flex flex-row justify-between">
        <div class="flex-1 flex-grow">
          <template v-if="currentStep === 2 && createGroupAndUser">
            <Button
              type="secondary"
              @onClick="createGroupBack"
              key="btn_group_back"
              :loading="isLoading"
              class="min-w-120px"
            >
              Back
            </Button>
          </template>
        </div>
        <div class="flex-0 my-auto mx-1 flex-shrink">
          <ul v-if="createGroupAndUser" class="dot-list">
            <li class="dot" :class="{ active: this.currentStep === 1 }"></li>
            <li class="dot" :class="{ active: this.currentStep === 2 }"></li>
            <li class="dot" :class="{ active: this.currentStep === 3 }"></li>
          </ul>
        </div>
        <div class="flex-1 flex justify-end flex-grow">
          <template v-if="currentStep === 1">
            <Button
              :primary-full-size="false"
              type="primary"
              @onClick="validateUserEntry"
              key="btn_finish_account"
              :disabled="stepOneButtonDisabled"
              :loading="isLoading"
              class="min-w-120px sm:min-w-150px"
            >
              {{ !createGroupAndUser ? 'Finish' : 'Continue' }}
            </Button>
          </template>
          <template v-else-if="currentStep === 2">
            <Button
              :primary-full-size="false"
              class="flex-end min-w-120px sm:min-w-150px"
              type="primary"
              @onClick="createNewGroup"
              key="btn_finish_account"
              :loading="isLoading"
            >
              {{ createGroupAndUser ? 'Continue' : 'Create Group' }}
            </Button>
          </template>
          <template v-else-if="currentStep === 3">
            <Button
              :primary-full-size="false"
              type="primary"
              @onClick="validateEditProfile"
              key="btn_finish_account"
              :loading="isLoading"
              class="min-w-120px sm:min-w-150px"
            >
              Create Profile
            </Button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/Button.vue';
import SignupFormStepOne from '@/components/SignupFormStepOne.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import GroupDetailsEdit from '@/components/groups/GroupDetailsEdit.vue';
import EditProfileForm from '@/components/EditProfileForm.vue';
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { validateRecaptchaToken } from '@/services/global.service';
import { validateEmail } from '../../helpers';

export default {
  name: 'CreateGroupFlow',
  components: {
    Button,
    ErrorBox,
    SignupFormStepOne,
    GroupDetailsEdit,
    EditProfileForm,
  },
  computed: {
    ...mapGetters(['globalErrors', 'isLoading', 'user', 'currentProfile']),
    stepOneButtonDisabled() {
      return this.isLoading || (this.createGroupAndUser && this.newUser.password.length < 8) || (this.createGroupAndUser && !this.isRecaptchaValid);
    },
    stepTwoButtonDisabled() {
      return this.isLoading || !this.newUser.profilePicture;
    },
  },
  watch: {
    globalErrors(errors) {
      errors.forEach((error) => {
        // send members back to step one if there was an issue with the email or password
        if (error.message && error.fieldName.indexOf('email') >= 0) {
          this.currentStep = 1;
          this.invalidFields.push('email');
        }
        if (error.message && error.fieldName.indexOf('firstName') >= 0) {
          this.currentStep = 1;
          this.invalidFields.push('firstName');
        }
        if (error.message && error.fieldName.indexOf('lastName') >= 0) {
          this.currentStep = 1;
          this.invalidFields.push('lastName');
        }
        if (error.message && error.fieldName.indexOf('password') >= 0) {
          this.currentStep = 1;
          this.invalidFields.push('password');
        }
        // if there are none from the first page
        if (this.invalidFields.length === 0) {
          if (error.message && error.fieldName.indexOf('name') >= 0) {
            this.currentStep = 2;
            this.invalidFields.push('name');
          }
        }
      });
    },
  },
  created() {
    this.determineFormSteps();
  },
  data() {
    return {
      groupSlug: '',
      newGroup: {
        name: '',
        primaryColor: '3, 129, 143',
        majorShowYearbook: true,
        schoolShowYearbook: true,
        employerYearbook: true,
        description: '',
        groupClassification: null,
      },
      newUser: {
        firstName: '',
        lastName: '',
        previousFirstName: '',
        previousLastName: '',
        email: '',
        password: '',
        city: '',
        state: '',
        country: '',
        showEmail: false,
        link: '',
        bio: '',
        isAttendingNextEvent: 'true',
        profilePicture: '',
        groupSlug: '',
        receiveAdminEmails: true,
      },
      currentStep: 1,
      createGroupAndUser: false, // false if the user is logged into existing account
      invalidFields: [],
      isRecaptchaValid: false,
      recaptchaErrorMsg: '',
    };
  },
  methods: {
    ...mapActions([
      'createGroup',
      'login',
      'updateUser',
      'clearGlobalErrors',
      'fetchCurrentUser',
      'updateWelcomeModalOpen',
      'updateCurrentUser',
      'checkUsernameExists',
    ]),
    saveProfileChanges(editedProfile, groupSlug) {
      if (editedProfile && editedProfile.slug) {
        this.showToast = false; // reset toast
        this.editError = null; // reset error
        const isAdmin = this.isSuperuser || (this.currentUser && this.currentUser.isGroupAdmin);
        const profileData = {
          groupSlug,
          profileSlug: editedProfile.slug,
          user: editedProfile,
          isAdmin,
        };

        // Google Analytics Event
        this.$gtag.event('save_profile', {
          event_category: 'profile',
          event_label: `${this.$route.params.group} / User clicked Save button`,
        });

        this.updateUser(profileData).then((response) => {
          if (response.success) {
            this.scrollToTop();
            this.fetchCurrentUser();
            this.updateCustomGroupValues(editedProfile);
            this.redirectToMembers();
          } else {
            this.editError = 'Sorry, your changes could not be saved. Please try again.';
          }
        });
      }
    },
    updateCustomGroupValues(editedProfile) {
      if (editedProfile.customGroupValues) {
        editedProfile.customGroupValues.forEach((field, index) => {
          let action = this.updateProfileFieldValue;
          if (this.profile.customGroupValues[index].value === null) {
            action = this.createProfileFieldValue;
          } else if (field.value === '') {
            action = this.removeProfileFieldValue;
          }

          action({
            groupSlug: this.$route.params.group,
            customFieldSlug: field.fieldSlug,
            value: field.value || '',
          });
        });
        this.fetchCurrentProfile();
      }
    },
    createGroupBack() {
      this.updateStep(1, true);
    },
    invitePeopleBack() {
      this.updateStep(3, true);
    },
    removeError(fieldName) {
      if (this.invalidFields.indexOf(fieldName) > -1) {
        this.invalidFields = this.invalidFields.filter((error) => error !== fieldName);
      }
    },
    determineFormSteps() {
      const token = localStorage.getItem('access-token');
      /*
        Authenticated and unauthenticated members will see different versions
        of this flow. An authenticated user only sees step 2 (group creation)
        whereas unauthenticated members will see all 3 steps or can login
       */
      if (token) {
        this.fetchCurrentUser().then(() => {
          // authenticated members with profile pics have completed profiles
          // App.js will redirect any user with an incomplete profile to finish it
          if (this.user.id) {
            this.createGroupAndUser = false;
            this.updateStep(2);
          }
        });
      } else {
        // if there's no user, use full form with create user option
        this.createGroupAndUser = true;
      }
    },
    updateStep(stepNumber, clearErrors = false) {
      this.currentStep = stepNumber;
      if (clearErrors) {
        this.clearGlobalErrors();
        this.invalidFields = [];
      }
      this.scrollToTop();
    },
    validateUserEntry() {
      this.checkUsernameExists(this.newUser.email).then((response) => {
        if (response.data.exists) {
          this.$store.commit('UPDATE_GLOBAL_ERRORS', [
            {
              fieldName: 'email',
              message: 'That email already exists',
            },
          ]);
        } else {
          if (!validateEmail(this.newUser.email)) {
            this.$store.commit('UPDATE_GLOBAL_ERRORS', [
              {
                fieldName: 'email',
                message: 'Please enter a valid email address',
              },
            ]);
            return;
          }
          this.updateStep(2, true);
        }
      });
    },
    createNewGroup() {
      // reset errors before submitting
      this.clearGlobalErrors();
      this.invalidFields = [];
      const {
        name,
        primaryColor,
        schoolShowYearbook,
        majorShowYearbook,
        employerShowYearbook,
        description,
        groupClassification,
        groupPhoto,
      } = this.newGroup;
      let options = {
        name,
        primaryColor,
        schoolShowYearbook,
        majorShowYearbook,
        employerShowYearbook,
        description,
        groupClassification,
        groupPhoto,
        isPublic: true,
      };

      if (this.createGroupAndUser) {
        // set user object
        options = {
          ...options,
          ...this.newUser,
        };
      }

      this.createGroup(options).then((res) => {
        if (res.success) {
          this.groupSlug = res.data.slug;
          if (this.createGroupAndUser) {
            this.sendTrackingEvents();
            // move on to step 3
            this.handleLoginUser();
          } else {
            this.sendTrackingEvents();
            localStorage.setItem('first-session-type', 'created-group');
            localStorage.setItem('invite-modal-opened', '');
            this.fetchCurrentUser().then(() => {
              this.redirectToMembers();
            });
          }
        }
        this.$forceUpdate();
      });
    },
    handleLoginUser() {
      this.login(this.newUser).then((data) => {
        if (data.success) {
          this.fetchCurrentUser().then(() => {
            this.updateStep(3, true);
          });
        }
        // else global errors will show
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    validateEditProfile() {
      if (this.$refs.editProfileForm.checkForErrors()) {
        return;
      }
      this.$refs.editProfileForm.updateProfile();
      localStorage.setItem('subscribe-hidden-date', `${DateTime.now().toUnixInteger()}`);
    },
    redirectToMembers() {
      const openWelcomeModal = true;
      this.updateWelcomeModalOpen(openWelcomeModal);
      this.$router.push({
        name: 'members',
        params: {
          group: this.groupSlug,
        },
      });
    },
    handlePhotoUpload(blob) {
      this.newUser.profilePicture = blob;
      this.removeError('profilePicture');
    },
    handleUpdateCity(city) {
      this.removeError('city');
      this.newUser.city = city;
    },
    handleUpdateState(state) {
      this.newUser.state = state;
    },
    handleUpdateCountry(country) {
      this.newUser.country = country;
    },
    uploadGroupPhoto(blob) {
      this.newGroup.groupPhoto = blob;
    },
    sendTrackingEvents() {
      if (this.createGroupAndUser) {
        // Google Analytics Event
        this.$gtag.event('created_account', {
          event_category: 'registration',
          event_label: 'User created account',
        });
      }

      // Google Analytics Event
      this.$gtag.event('created_group', {
        event_category: 'registration',
        event_label: 'User created group',
      });

      if (typeof window.fbq === 'function') {
        window.fbq('track', 'CompleteRegistration');
      }

      if (typeof window.snaptr === 'function') {
        window.snaptr('track', 'SIGN_UP');
      }
    },
    async validateRecaptcha(token) {
      const data = await validateRecaptchaToken(token);

      if (data?.isValid) {
        this.isRecaptchaValid = true;
      } else {
        this.isRecaptchaValid = false;
        this.recaptchaErrorMsg = 'There was an error with you reCAPTCHA test, please try again.';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-left-enter-active,
.fade-left-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-left-enter {
  opacity: 0;
  transform: translateX(100px);
}

.fade-left-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.dot-list {
  align-items: center;
  display: flex;
  justify-content: center;

  & li {
    margin: 0 4px;
    border-radius: 9999px;
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.3);

    &.active {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
