import { api } from '@/store';

export async function requestSubscriptionService(groupSlug, message, feature) {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  try {
    await api.post(
      `api/v1/groups/${groupSlug}/request-subscription/`,
      { message, feature },
      {
        headers: {
          ...baseHeader,
        },
      },
    );
  } catch (e) {
    console.log(e);
  }
}

export async function transferAccountService(groupSlug, profileId, email) {
  const body = {
    profile: profileId,
    to_email: email,
  };
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };

  await api.post(`api/v1/groups/${groupSlug}/transfer-profile/`, body, {
    headers: { ...baseHeader },
  });
}

export async function transferResetPasswordService(body) {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };

  await api.post('api/v1/profiles/complete-transfer/', body, {
    headers: { ...baseHeader },
  });
}

export async function fetchPaymentProvidersService() {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  try {
    const response = await api.get('api/v1/payment-providers/', {
      headers: { ...baseHeader },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function validateRecaptchaToken(token) {
  const data = { token };
  const headers = { 'Content-Type': 'application/json' };

  try {
    const response = await api.post('api/v1/validate-recaptcha/', data, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
}
