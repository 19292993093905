<template>
  <div class="w-full px-6 my-8 md:px-0 md:w-2/3 md:mx-auto lg:w-3/5 max-w-screen-md">
    <transition name="fade-left" mode="out-in">
      <template v-if="step === 1">
        <SignupFormStepOne
          :user="newUser"
          :invalidFields="invalidFields"
          :validate-recaptcha="validateRecaptcha"
        />
      </template>
      <template v-if="step === 2">
        <EditProfileForm
          ref="editProfileForm"
          :currentUser="user"
          :profile="user"
          :onSave="saveProfileChanges"
        />
      </template>
    </transition>
    <template v-if="invalidFields.length">
      <ErrorBox :message="getErrorMessage(invalidFields)" />
    </template>
    <template v-if="globalErrors.length">
      <ErrorBox v-for="(error, index) in globalErrors" :message="error" :key="`error${index}`" />
    </template>
    <template v-if="recaptchaErrorMsg">
      <ErrorBox :message="recaptchaErrorMsg" />
    </template>
    <div class="rounded bg-gray-200 p-3">
      <div class="flex flex-col sm:flex-row justify-between items-center">
        <div class="flex-1 mb-auto mt-auto">
          <ul class="dot-list">
            <li class="dot" :class="{ active: this.step == 1 }"></li>
            <li class="dot" :class="{ active: this.step == 2 }"></li>
          </ul>
        </div>
        <template v-if="step === 1">
          <Button
            type="primary"
            @onClick="handleCreateAccount"
            key="btn_create_account"
            :disabled="registrationDisabled"
            :loading="this.isLoading"
          >
            Create Account
          </Button>
        </template>
        <div v-if="step === 2">
          <Button
            type="primary"
            @onClick="validateEditProfile"
            key="btn_finish_account"
            :disabled="buttonIsDisabled"
            :loading="buttonIsDisabled"
          >
            Finish
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import SignupFormStepOne from '@/components/SignupFormStepOne.vue';
import EditProfileForm from '@/components/EditProfileForm.vue';
import { validateRecaptchaToken } from '@/services/global.service';

export default {
  name: 'Signup',
  components: {
    Button,
    ErrorBox,
    SignupFormStepOne,
    EditProfileForm,
  },
  created() {
    const token = localStorage.getItem('access-token');
    if (token) {
      this.fetchCurrentUser().then(() => {
        // if the profile picture is set, they've already gone through step 2
        if (this.user) {
          this.redirectToMembers();
        }
        // make sure there is a user before moving on to step 2
        if (this.user.id) {
          this.newUser = { ...this.user };
          this.step = 2;
          if (this.user.isAttendingNextEvent) {
            this.newUser.isAttendingNextEvent = this.newUser.isAttendingNextEvent.toString();
          } else {
            this.newUser.isAttendingNextEvent = 'false';
          }
        }
      });
    }
    if (!this.$route.params.isFromMembers) {
      this.redirectToMembers();
    }
  },
  computed: {
    ...mapGetters(['user', 'isLoading', 'globalErrors']),
    hasInvalidFields() {
      return !!this.invalidFields.length;
    },
    registrationDisabled() {
      return this.isLoading || !this.isRecaptchaValid;
    },
    buttonIsDisabled() {
      return this.isLoading;
    },
  },
  data() {
    return {
      newUser: {
        firstName: '',
        lastName: '',
        previousFirstName: '',
        previousLastName: '',
        email: '',
        password: '',
        city: '',
        state: '',
        country: '',
        showEmail: false,
        link: '',
        bio: '',
        isAttendingNextEvent: 'false',
        profilePicture: '',
        groupSlug: '',
        receiveAdminEmails: true,
      },
      step: 1,
      requiredFields_1: ['firstName', 'lastName', 'email', 'password'],
      requiredFields_2: ['profilePicture'],
      invalidFields: [],
      isRecaptchaValid: false,
      recaptchaErrorMsg: '',
    };
  },
  methods: {
    ...mapActions([
      'registerUser',
      'login',
      'updateUser',
      'fetchCurrentUser',
      'updateWelcomeModalOpen',
    ]),
    handleCreateAccount() {
      const registerData = {
        groupSlug: this.$route.params.group,
        user: this.newUser,
      };

      this.checkInvalidFields();
      if (this.hasInvalidFields) return;

      this.registerUser(registerData).then((data) => {
        if (data.success) {
          this.sendTrackingEvents();
          this.handleLoginUser();
        }
      });
    },
    handleLoginUser() {
      this.login(this.newUser).then((data) => {
        if (data.success) {
          this.step = 2;
          this.scrollToTop();
        }
      });
    },
    handleCompleteProfile() {
      // Strip the https?:// from the link
      this.newUser.link = this.newUser.link && this.newUser.link.replace(RegExp('https?://'), '');
      if (this.user && this.user.slug) {
        this.checkInvalidFields();
        if (this.hasInvalidFields) return;

        const profileData = {
          groupSlug: this.$route.params.group,
          profileSlug: this.user.slug,
          user: this.newUser,
          isAdmin: false,
        };

        this.updateUser(profileData).then((data) => {
          if (data.success) {
            // Google Analytics Event
            this.$gtag.event('completed_profile', {
              event_category: 'registration',
              event_label: `${this.$route.params.group} / User completed profile`,
            });
            this.updateCustomGroupValues(profileData);
            this.fetchCurrentUser();
            localStorage.setItem('first-session-type', 'joined-group');
            localStorage.setItem('invite-modal-opened', '');
            this.redirectToMembers();
          }
        });
      }
    },
    redirectToMembers() {
      if (this.$route.params.isFromMembers) {
        this.updateWelcomeModalOpen(true);
      }

      this.$router.push({
        name: 'members',
        params: {
          group: this.$route.params.group,
        },
      });
    },
    removeError(fieldName) {
      if (this.invalidFields.indexOf(fieldName) > -1) {
        this.invalidFields = this.invalidFields.filter((error) => error !== fieldName);
      }
    },
    handlePhotoUpload(blob) {
      this.newUser.profilePicture = blob;
      this.removeError('profilePicture');
    },
    handleUpdateCity(city) {
      this.newUser.city = city;
    },
    handleUpdateState(state) {
      this.newUser.state = state;
    },
    handleUpdateCountry(country) {
      this.newUser.country = country;
    },
    checkInvalidFields() {
      // reset errors before checking for them
      this.invalidFields = [];
      if (this.step === 1) {
        this.requiredFields_1.forEach((field) => {
          if (!this.newUser[field] || !this.newUser[field].trim()) {
            this.invalidFields.push(field);
          }
        });
      }
      if (this.step === 2) {
        this.requiredFields_2.forEach((field) => {
          if (!this.newUser[field]) {
            this.invalidFields.push(field);
          }
        });
      }
    },
    getErrorMessage(fields) {
      const errors = fields
        .map((error) => {
          let formattedError = error;
          if (error === 'firstName') {
            formattedError = 'first name';
          } else if (error === 'lastName') {
            formattedError = 'last name';
          } else if (error === 'profilePicture') {
            formattedError = 'profile picture';
          }
          return formattedError;
        })
        .join(', ');
      return `Required field${fields.length > 1 ? 's' : ''}: ${errors}`;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    sendTrackingEvents() {
      // Google Analytics Event
      this.$gtag.event('created_account', {
        event_category: 'registration',
        event_label: `${this.$route.params.group} / User created account`,
      });

      if (typeof window.fbq === 'function') {
        window.fbq('track', 'CompleteRegistration');
      }

      if (typeof window.snaptr === 'function') {
        window.snaptr('track', 'SIGN_UP');
      }
    },
    validateEditProfile() {
      if (this.$refs.editProfileForm.checkForErrors()) {
        return;
      }
      this.$refs.editProfileForm.updateProfile();
    },
    saveProfileChanges(editedProfile, groupSlug) {
      if (editedProfile) {
        this.showToast = false; // reset toast
        this.editError = null; // reset error
        const isAdmin =
          this.isSuperuser || (this.currentUser && this.currentUser.isGroupAdmin) || false;
        const profileData = {
          groupSlug,
          profileSlug: editedProfile.slug,
          user: editedProfile,
          isAdmin,
        };

        // Google Analytics Event
        this.$gtag.event('save_profile', {
          event_category: 'profile',
          event_label: `${this.$route.params.group} / User clicked Save button`,
        });
        this.updateUser(profileData).then((response) => {
          if (response.success) {
            // Google Analytics Event
            this.$gtag.event('completed_profile', {
              event_category: 'registration',
              event_label: `${this.$route.params.group} / User completed profile`,
            });

            this.updateCustomGroupValues(editedProfile);
            this.fetchCurrentUser();

            localStorage.setItem('first-session-type', 'joined-group');
            localStorage.setItem('invite-modal-opened', '');
            this.redirectToMembers();
          } else {
            this.editError = 'Sorry, your changes could not be saved. Please try again.';
          }
        });
      }
    },
    updateCustomGroupValues(editedProfile) {
      if (editedProfile.customGroupValues) {
        editedProfile.customGroupValues.forEach((field) => {
          this.createProfileFieldValue({
            groupSlug: this.$route.params.group,
            customFieldSlug: field.fieldSlug,
            value: field.value || '',
          });
        });
      }
    },
    async validateRecaptcha(token) {
      const data = await validateRecaptchaToken(token);

      if (data?.isValid) {
        this.isRecaptchaValid = true;
      } else {
        this.isRecaptchaValid = false;
        this.recaptchaErrorMsg = 'There was an error with you reCAPTCHA test, please try again.';
      }
    },
  },
};
</script>
<style scoped lang="scss">
.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}
.fade-up-enter,
.fade-up-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.fade-left-enter-active,
.fade-left-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}
.fade-left-enter {
  opacity: 0;
  transform: translateX(100px);
}

.fade-left-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.dot-list {
  align-items: center;
  display: flex;
  justify-content: center;
  & li {
    margin: 0 4px;
    border-radius: 9999px;
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.3);

    &.active {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
