<template>
  <div>
    <div class="rounded no-print relative">
      <button
        type="button"
        class="w-full flex md:hidden mb-6 text-lg font-semibold cursor-pointer h-11 items-center gap-3 self-stretch rounded border border-gray-300 bg-gray-100 shadow-drop pl-4 py-3 border-solid"
        @click="toggleExpandedMobileRow"
      >
        <IconWork
          class="w-3"
          :class="currentPageView === PAGES.subscription ? 'block' : 'hidden'"
        />
        <IconSettings
          class="w-3"
          :class="currentPageView === PAGES.settings ? 'block' : 'hidden'"
        />
        <IconUsers class="w-3" :class="currentPageView === PAGES.members ? 'block' : 'hidden'" />
        <IconGroupSettings
          class="w-3"
          :class="currentPageView === PAGES.groupSettings ? 'block' : 'hidden'"
        />

        <span class="md:pl-0 text-gray-900 text-sm font-semibold">
          {{ formattedCurrentView }}
        </span>
        <span
          class="md:hidden bg-gray-100 w-10 h-10 md:mr-4 flex rounded ml-auto items-center justify-center animation-focus-default"
        >
          <span
            class="inline-block transition-transform duration-200 ease transform text-gray-600"
            :class="[
              {
                'rotate-180': expandedMobileView,
              },
            ]"
          >
            <Chevron class="w-4 h-4" />
          </span>
        </span>
      </button>

      <div class="block md:hidden border-b border-gray-400 w-full h-[1px] mb-6 opacity-40"></div>
      <ul
        class="flex-col px-0 md:px-4 gap-2 absolute md:static top-[47px] z-10 left-0 bg-white rounded-md w-full shadow-drop md:shadow-none"
        :class="!isMobile || (isMobile && expandedMobileView) ? 'flex' : 'hidden'"
      >
        <li
          :class="{
            'bg-gray-900': currentPageView === PAGES.subscription,
            'group hover:bg-gray-100': currentPageView !== PAGES.subscription,
          }"
          class="flex gap-2.5 px-4 py-2 rounded-lg cursor-pointer items-center"
          @click="handleNavLinkClick(PAGES.subscription)"
        >
          <IconWork
            class="w-3.5"
            :class="{ 'admin-navigation__icon--active': currentPageView === PAGES.subscription }"
          />
          <h6
            class="group-hover:text-gray-800"
            :class="currentPageView === PAGES.subscription ? 'text-white' : 'text-gray-700'"
          >
            Subscription
          </h6>
        </li>
        <li
          :class="{
            'bg-gray-900': currentPageView === PAGES.settings,
            'group hover:bg-gray-100': currentPageView !== PAGES.settings,
          }"
          class="flex gap-2.5 px-4 py-2 rounded-lg cursor-pointer items-center"
          @click="handleNavLinkClick(PAGES.settings)"
        >
          <IconSettings
            class="w-3.5"
            :class="{ 'admin-navigation__icon--active': currentPageView === PAGES.settings }"
          />
          <h6
            class="group-hover:text-gray-800"
            :class="currentPageView === PAGES.settings ? 'text-white' : 'text-gray-700'"
          >
            Member Profile Settings
          </h6>
        </li>
        <li
          :class="{
            'bg-gray-900': currentPageView === PAGES.members,
            'group hover:bg-gray-100': currentPageView !== PAGES.members,
          }"
          class="flex gap-2.5 px-4 py-2 rounded-lg cursor-pointer items-center"
          @click="handleNavLinkClick(PAGES.members)"
        >
          <IconUsers
            class="w-3.5"
            :class="{ 'admin-navigation__icon--active': currentPageView === PAGES.members }"
          />
          <h6
            class="group-hover:text-gray-800"
            :class="currentPageView === PAGES.members ? 'text-white' : 'text-gray-700'"
          >
            Manage Members
          </h6>
        </li>
        <li
          :class="{
            'bg-gray-900': currentPageView === PAGES.groupSettings,
            'group hover:bg-gray-100': currentPageView !== PAGES.groupSettings,
          }"
          class="flex gap-2.5 px-4 py-2 rounded-lg cursor-pointer items-center"
          @click="handleNavLinkClick(PAGES.groupSettings)"
        >
          <IconGroupSettings
            class="w-3.5"
            :class="{ 'admin-navigation__icon--active': currentPageView === PAGES.groupSettings }"
          />
          <h6
            class="group-hover:text-gray-800"
            :class="currentPageView === PAGES.groupSettings ? 'text-white' : 'text-gray-700'"
          >
            Group Settings
          </h6>
        </li>
        <li
          :class="{
            'bg-gray-900': currentPageView === PAGES.adminVideos,
            'group hover:bg-gray-100': currentPageView !== PAGES.adminVideos,
          }"
          class="flex gap-2.5 px-4 py-2 rounded-lg cursor-pointer items-center"
          @click="handleNavLinkClick(PAGES.adminVideos)"
        >
          <span
            class="w-3.5"
            :class="{ 'admin-navigation__icon--active': currentPageView === PAGES.adminVideos }"
          >
            <VideoIcon />
          </span>
          <h6
            class="group-hover:text-gray-800"
            :class="currentPageView === PAGES.adminVideos ? 'text-white' : 'text-gray-700'"
          >
            Admin Videos
          </h6>
        </li>

        <!--        <li-->
        <!--          :class="{-->
        <!--            'hidden sm:block': !expandedMobileView,-->
        <!--          }"-->
        <!--          class="my-auto sm:ml-auto pb-6 sm:pb-0 pl-4 sm:pl-0"-->
        <!--        >-->
        <!--          <Button-->
        <!--            v-if="group.isSubscribed"-->
        <!--            :primary-full-size="false"-->
        <!--            color-custom-->
        <!--            type="primary"-->
        <!--            @onClick="printDiv"-->
        <!--            class="sm:mr-4 mb-4 sm:mb-0"-->
        <!--          >-->
        <!--            <template v-slot:icon>-->
        <!--              <PrintIcon />-->
        <!--            </template>-->
        <!--            Print Directory-->
        <!--          </Button>-->
        <!--        </li>-->
      </ul>
    </div>
    <PrintView class="only-print" />
  </div>
</template>

<script>
import IconWork from '@/components/svgComponents/IconWork.vue';
import IconSettings from '@/components/svgComponents/IconSettings.vue';
import IconUsers from '@/components/svgComponents/IconUsers.vue';
import Chevron from '@/assets/chevron.svg';
import VideoIcon from '@/assets/icon_video.svg';
import { mapGetters } from 'vuex';
import IconGroupSettings from '@/components/svgComponents/IconGroupSettings.vue';
import PrintView from '../views/Group/PrintView.vue';
import { constants } from '../config';

const PAGES = {
  members: 'members',
  subscription: 'subscription',
  settings: 'settings',
  groupSettings: 'group-settings',
  adminVideos: 'admin-videos',
};

export default {
  name: 'AdminSubNavigation',
  components: {
    IconGroupSettings,
    IconUsers,
    IconSettings,
    IconWork,
    Chevron,
    PrintView,
    VideoIcon,
  },
  props: {
    /**
     * string for which nav options is currently selected
     */
    currentPageView: {
      type: String,
    },
    /**
     * action to update currentPageView on nav link click
     */
    updateCurrentPageView: {
      type: Function,
    },
  },
  data() {
    return {
      PAGES,
      expandedMobileView: false,
      cbInstance: null,
      cart: null,
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters(['group', 'user', 'settingsGroupFeatureFlags']),
    formattedCurrentView() {
      switch (this.currentPageView) {
        case PAGES.settings:
          return 'Member Profile Settings';
        case PAGES.members:
          return 'Manage Members';
        case PAGES.groupSettings:
          return 'Group Settings';
        case PAGES.adminVideos:
          return 'Admin Videos';
        default:
          return 'Subscription';
      }
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    // eslint-disable-next-line no-undef
    this.cbInstance = Chargebee.init({
      site: constants.CHARGEBEE_SOURCE,
      isItemsModel: true,
    });
    this.cbInstance.isItemsModel = true;
    this.cart = this.cbInstance.getCart();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 840;
    },
    toggleExpandedMobileRow() {
      this.expandedMobileView = !this.expandedMobileView;
    },
    handleNavLinkClick(view) {
      this.expandedMobileView = false;
      this.updateCurrentPageView(view);
    },
    printDiv() {
      window.print();
    },
  },
};
</script>

<style lang="scss">
.admin-navigation__icon--active {
  path,
  circle {
    @apply stroke-white;
  }
}
</style>
